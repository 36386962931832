import styled from "styled-components";

export const TransparentButton = styled.button`
  width: 80%;
  padding: 1rem;
  border: 3px solid #fff3;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff3;
  text-decoration: none;
  color: #fff;
  font-size: 1.5rem;
  margin: 1rem 0;
  cursor: pointer;
`;
