import React from "react";
import styled from "styled-components";
import { Title } from "../reusable/text";

interface iProps {
  title?: string;
  left?: JSX.Element | string;
  right?: JSX.Element | string;
}

const Navbar = ({ left, right, title }: iProps) => {
  return (
    <NavbarComponent>
      {(Boolean(left) && left) || <Holder size={1} />}
      {(Boolean(title) && <Title>{title}</Title>) || <Holder size={4} />}
      {(Boolean(right) && right) || <Holder size={1} />}
    </NavbarComponent>
  );
};

export default Navbar;

const NavbarComponent = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > a,
  > button {
    flex: 1;
  }
  > h1 {
    flex: 4;
  }
`;

interface iHolder {
  size: number;
}

const Holder = styled.span<iHolder>`
  flex: ${(props) => props.size || 1};
`;
