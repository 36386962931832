import styled from "styled-components";

interface iProps {
  height: string;
}

export const Split = styled.span<iProps>`
  width: 100%;
  padding: 0;
  margin: 0;
  display: block;
  height: ${(p) => p.height};
`;
