import styled from "styled-components";

interface iFullscreenWrapper {
  bg?: string;
  textColor?: string;
}

const FullscreenWrapper = styled.div<iFullscreenWrapper>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: ${(p) => p.theme.background || "#fff3"};
  z-index: 111;
`;

export default FullscreenWrapper;
