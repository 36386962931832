import React from "react";
import { DefaultTheme, ThemeProvider } from "styled-components";

interface iProps {
  children: JSX.Element;
}

interface iTheme extends DefaultTheme {
  background: string;
}

const theme: iTheme = {
  background: "#08f",
};

const Theme = ({ children }: iProps) => {
  const [themeObj, setThemeObj] = React.useState<iTheme>(theme);

  const updateThemeFromLocalStorage = async () => {
    const theme = localStorage.getItem("theme") as string;
    let background = "#08f";
    try {
      background = JSON.parse(theme).background;
    } catch (e) {
      // pass
    }

    setThemeObj((currTheme) => ({
      ...currTheme,
      background,
    }));

    document.body.style.setProperty("--bg-color", background);
  };

  React.useEffect(() => {
    updateThemeFromLocalStorage();

    window.addEventListener(
      "custom::theme-change",
      updateThemeFromLocalStorage
    );
    return () =>
      window.removeEventListener(
        "custom::theme-change",
        updateThemeFromLocalStorage
      );
  }, []);

  return <ThemeProvider theme={themeObj}>{children}</ThemeProvider>;
};

export default Theme;
