import styled from "styled-components";
import CenterContent from "../reusable/CenterContent";
import FullscreenWrapper from "../reusable/FullscreenWrapper";

interface iProps {
  bg?: string;
  text?: string;
}

const Loader = (props: iProps) => (
  <LoaderWrapper {...props}>
    <CenterContent>
      <img src="/images/muscle-flex-transparent.gif" alt="loader" />
      <h1>{props.text}</h1>
    </CenterContent>
  </LoaderWrapper>
);

export default Loader;

const LoaderWrapper = styled(FullscreenWrapper)<iProps>`
  > * > img {
    width: 154px;
    height: 100px;
  }
  > * > h1 {
    font-size: 2rem;
    font-weight: bold;
    color: ${(p) => p.textColor || "#fff"};
    margin-top: 1rem;
  }
`;
