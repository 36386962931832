import styled from "styled-components";

export const Title = styled.h1`
  width: 100%;
  text-align: center;
  color: #fff;
  margin: 1.5rem;
  font-size: 3rem;
`;

interface iText {
  textAlign?: string;
}

export const Text = styled.p<iText>`
  width: 100%;
  font-size: 1rem;
  color: #fff;
  text-align: ${(p) => p.textAlign || "left"};
`;
