import styled from "styled-components";
import Navbar from "../../components/Navbar/Navbar";
import { AllAroundCenterContentWrapper } from "../../components/reusable/AllAroundCenterContentWrapper";
import { Split } from "../../components/reusable/Split";
import { Text } from "../../components/reusable/text";
import { TransparentButton } from "../../components/reusable/TransparentButton";

interface iProps {
  error: string;
}

const ErrorComponent = ({ error }: iProps) => {
  const handleReload = () => {
    window.location.reload();
  };

  const purgeLocalStorage = () => {
    if (
      window.confirm("Are you sure?\nThis will delete all your local data!")
    ) {
      localStorage.setItem("data", "");
      handleReload();
    }
  };

  return (
    <AllAroundCenterContentWrapper>
      <Navbar
        title="Error"
        left={
          <TransparentButton onClick={handleReload} title="Reload application">
            <i className="fas fa-redo" />
          </TransparentButton>
        }
        right={
          <TransparentButton
            onClick={purgeLocalStorage}
            title="Purge local data"
          >
            <i className="fas fa-trash" />
          </TransparentButton>
        }
      />
      <Split height="1rem" />
      <Text textAlign="center">{error}</Text>
    </AllAroundCenterContentWrapper>
  );
};

export default ErrorComponent;
