import styled from "styled-components";

export const AllAroundCenterContentWrapper = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(p) => p.theme.background};
  padding: 0 1rem;
`;
