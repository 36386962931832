import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import Loader from "./components/loader/Loader";
import ErrorComponent from "./containers/ErrorComponent/ErrorComponent";

import Theme from "./theme/Theme";
import InMemoryDB from "./utils/local-db";

import "./App.css";

const Home = React.lazy(() => import("./containers/home/Home"));
const DailyList = React.lazy(() => import("./containers/DailyList/DailyList"));
const DailyEdit = React.lazy(() => import("./containers/DailyEdit/DailyEdit"));
const History = React.lazy(() => import("./containers/History/History"));
const FoodList = React.lazy(() => import("./containers/FoodList/FoodList"));
const Settings = React.lazy(() => import("./containers/settings/Settings"));
const Help = React.lazy(() => import("./containers/help/Help"));
const Measures = React.lazy(() => import("./containers/Measures/Measures"));

function App() {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [localError, setLocalError] = React.useState("");

  React.useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, getRnd(2000, 4000));
  }, []);

  React.useEffect(() => {
    try {
      populateDbObject().then((defaultData) => {
        const lsData = localStorage.getItem("data");
        if (lsData) {
          const data = JSON.parse(lsData);
          delete data.food;
          delete data.foodReplaces;
          const dataObjMerged = DeepMergeObjects(defaultData, data);
          InMemoryDB.setData(dataObjMerged);

          InMemoryDB.dumpToLocalStorage();
        } else {
          localStorage.setItem("data", JSON.stringify(defaultData));
          InMemoryDB.setData(defaultData);
        }
      });
    } catch (e: any) {
      setLocalError(e.message);
    }
  }, []);

  if (localError) {
    return (
      <Theme>
        <ErrorComponent error={localError} />
      </Theme>
    );
  }

  if (!isLoaded) {
    return (
      <Theme>
        <Loader bg="#08f" text="Loading..." />
      </Theme>
    );
  }

  return (
    <Theme>
      <Suspense fallback={<Loader text="Loading..." />}>
        <Router>
          <React.Fragment>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/daily" component={DailyList} />
              <Route exact path="/daily/edit" component={DailyEdit} />
              <Route exact path="/history" component={History} />
              <Route exact path="/food-list" component={FoodList} />
              <Route exact path="/settings" component={Settings} />
              <Route exact path="/help" component={Help} />
              <Route exact path="/measures" component={Measures} />
              <Route render={() => <Redirect to="/" />} />
            </Switch>
          </React.Fragment>
        </Router>
      </Suspense>
    </Theme>
  );
}

export default App;

const getRnd = (min: number, max: number) => {
  return Math.min(
    min + (parseInt(Math.random().toString().slice(2), 10) % (max - min)),
    max
  );
};

const defaultDBObject = {
  metadata: {
    tables: ["food", "foodReplaces", "history", "dailySettings", "measures"],
    columns: {
      food: ["id", "name", "calories", "quantity"],
      foodReplaces: ["id", "sourceId", "destinationId", "ratio"],
      history: ["id", "date", "foodId", "amount"],
      dailySettings: ["id", "foodId", "amount"],
      measures: [
        "id",
        "date",
        "visina",
        "tezina",
        "butina",
        "straznjica",
        "stomak1",
        "stomak2",
        "prsa",
        "biceps",
      ],
    },
  },
  food: [],
  foodReplaces: [],
  dailySettings: [],
  history: [],
  measures: [],
};

const populateDbObject = async () => {
  const req = await fetch("/food.json");
  const { food, foodReplaces, dailySettings } = await req.json();
  defaultDBObject.food = food;
  defaultDBObject.foodReplaces = foodReplaces;
  defaultDBObject.dailySettings = dailySettings;
  defaultDBObject.measures = [];

  return defaultDBObject;
};

const DeepMergeObjects = (obj1: any, obj2: any): any => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  const keys = new Set(keys1.concat(keys2));

  const finalObj: any = {};

  keys.forEach((key) => {
    if (obj1[key] && obj2[key]) {
      if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
        finalObj[key] = ConcatArrayByUniqueId(obj1[key], obj2[key], "id");
      } else if (
        typeof obj1[key] === "object" &&
        typeof obj2[key] === "object"
      ) {
        finalObj[key] = DeepMergeObjects(obj1[key], obj2[key]);
      } else {
        finalObj[key] = obj2[key];
      }
    } else if (obj1[key]) {
      finalObj[key] = obj1[key];
    } else if (obj2[key]) {
      finalObj[key] = obj2[key];
    }
  });

  return finalObj;
};

const ConcatArrayByUniqueId = (arr1: any, arr2: any, id = "id"): any[] => {
  const finalArr = arr1;
  arr2.forEach((item: any) => {
    const index = finalArr.findIndex((i: any) => i[id] === item[id]);
    if (index === -1) {
      finalArr.push(item);
    } else {
      finalArr[index] = { ...finalArr[index], ...item };
    }
  });
  return finalArr.sort((a: any, b: any) => (a[id] > b[id] ? 1 : -1));
};

(window as unknown as any).DeepMergeObjects = DeepMergeObjects;
