/* query specs */
/*
    {
        table: 'User',
        type: 'single' | 'all',
        condition: (parent, child)=> parent.user_id === child.id // or any other valid condition,
        as: 'localUser' // or any other name you want to read object from parent
    }
*/

/* condition specs */
/*
    (row) => row.id === someUserVariable
*/

export interface iQuery {
  table: string;
  type: "single" | "all";
  condition: (parent: any, child: any) => boolean;
  as?: string;
}

class InMemoryDB {
  private tables: string[] = [];
  private columns: { [index: string]: string } = {};
  private data: { [index: string]: any[] } = {};

  setData(data: any, options?: any) {
    const metadata = {
      tables: data?.metadata?.tables || options?.tables || null,
      columns: data?.metadata?.columns || options?.columns || null,
    };

    if (
      !metadata.tables ||
      !metadata.columns ||
      (metadata.tables?.length && !Object.keys(metadata.columns).length)
    ) {
      throw new Error(
        "You need to pass both metadata about tables and columns"
      );
    }

    this.tables = [];
    this.columns = {};
    this.data = { ...data, metadata: undefined };
  }

  /**
   * Function that search for one row and returns it or null if no row found
   * @param {string} table Name of table where condition will be checked
   * @param {func} condition function that will be used to check condition
   * @param {Array} queries Array of queries that will be used to include other tables in result
   * @returns Single row or null if no row found
   */
  findOne(table: string, condition?: any, queries?: iQuery[]) {
    const tableData = this.data[table];
    if (!tableData) {
      throw new Error(`Table ${table} not found`);
    }

    let row = tableData.find(condition || (() => true)) || null;

    if (row && queries?.length) {
      row = this.handleQueries(row, queries);
    }
    return row;
  }

  /**
   * Function that search for all rows and returns them or empty array if no rows found
   * @param {string} table Name of table where condition will be checked
   * @param {func} condition function that will be used to check condition
   * @param {Array} queries Array of queries that will be used to include other tables in result
   * @returns Array or result rows or empty array if no rows found
   */
  findAll<T = any>(table: string, condition?: any, queries?: iQuery[]): T[] {
    const tableData = this.data[table];
    if (!tableData) {
      throw new Error(`Table ${table} not found`);
    }

    const rows = tableData
      .filter(condition || (() => true))
      .map((row: any) => this.handleQueries(row, queries || []));
    return rows;
  }

  create<T = any>(table: string, data: T, options?: any): T {
    const tableData = this.data[table];
    if (!tableData) {
      throw new Error(`Table ${table} not found`);
    }

    const lastItem = tableData.slice(-1)[0];

    (data as any).id = lastItem?.id ? lastItem.id + 1 : 1;

    tableData.push(data);

    if (options?.hardSave) {
      this.dumpToLocalStorage();
    }

    return data;
  }

  delete<T = any>(table: string, id: number): T {
    const tableData = this.data[table];
    if (!tableData) {
      throw new Error(`Table ${table} not found`);
    }

    const deletedRow = tableData.find((row: any) => row.id === id);
    if (deletedRow) {
      this.data[table] = tableData.filter((row: any) => row.id !== id);
    }
    return deletedRow;
  }

  update<T = any>(table: string, id: number, data: T): T {
    const tableData = this.data[table];
    if (!tableData) {
      throw new Error(`Table ${table} not found`);
    }

    const updatedRow = tableData.find((row: any) => row.id === id);
    if (updatedRow) {
      this.data[table] = tableData.map((row: any) =>
        row.id === id ? { ...row, ...data } : row
      );
    }
    return updatedRow;
  }

  private handleQueries(row: any, queries: iQuery[]) {
    queries.forEach((query: iQuery) => {
      if (query.table && query.type && query.condition) {
        if (!query.as === undefined) query.as = query.table;

        if (query.type === "single") {
          row[query.as as string] =
            this.data[query.table].find((child: any) =>
              query.condition(row, child)
            ) || null;
        } else if (query.type === "all") {
          row[query.as as string] =
            this.data[query.table].filter((child) =>
              query.condition(row, child)
            ) || [];
        }
      } else {
        console.warn("Invalid query", JSON.stringify(query));
      }
    });

    return row;
  }

  dumpToLocalStorage(): boolean {
    try {
      localStorage.setItem("data", JSON.stringify(this.data));
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }
}

const instance = new InMemoryDB();

(window as unknown as any).dbLocal = instance;

export default instance;
